'use strict';

// stylesheet import
import 'swiper/css/bundle';
import '../scss/main.scss';

import { createApp } from 'vue';
// import { store } from './store.js';
import MainSlide from './vue/MainSlide.vue';
import FeatureMovies from './vue/FeatureMovies.vue';
import { IntersectionObserverToggleClass } from './modules/class-intersection-observer-toggleclass.js';
import { IsLegacyIE } from './modules/checking-legacy-ie.js';
import { FindOS } from './modules/checking-os.js';
import { FindBrowser } from './modules/checking-browser.js';
import Swiper from 'swiper/bundle';
// const $ = require('jQuery');

// Vue.js
const mainSlide = createApp({});
mainSlide.component('main-slide', MainSlide).mount('#main_slide');
const featureMovies = createApp({});
featureMovies.component('feature-movies', FeatureMovies).mount('#feature_movies');

// Swiper
const swiperPosts = new Swiper('.js-slide-posts', {
  loop: true,
  centeredSlides: true,
  slidesPerView: 1.3,
  spaceBetween: 10,
  // pagination: {
  //   el: '.swiper-pagination',
  // },
  // navigation: {
  //   nextEl: '.swiper-button-next',
  //   prevEl: '.swiper-button-prev',
  // },
  // autoplay: {
  //   delay: 3000,
  // },
  breakpoints: {
    544: {
      slidesPerView: 2.5,
      spaceBetween: 30,
    },
    998: {
      slidesPerView: 3.5,
      spaceBetween: 60,
    },
    1480: {
      slidesPerView: 4.5,
      spaceBetween: 60,
    },
  }
});
const swiperTours = new Swiper('.js-slide-tours', {
  loop: true,
  slidesPerView: 1,
  autoplay: {
    delay: 3000,
  },
  speed: 1000,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
});

// jQuery
// $(function() {

//   // column tab
//   $('.js-tab-label').on('click', function() {
//     const currentTab = $(this).attr('data-tab');
//     $(this).siblings().removeClass('__tab-label--current');
//     $(this).addClass('__tab-label--current');
//     $(`.js-tab-panel[data-tab="${currentTab}"]`).siblings().removeClass('__tab-panel--current');
//     $(`.js-tab-panel[data-tab="${currentTab}"]`).addClass('__tab-panel--current');
//   });

//   // drawer menu
//   $('.js-hamburger').on('click', function() {
//     $(this).toggleClass('__close');
//     $('.js-drawer-navi').slideToggle();
//   });
//   $(window).on('resize', function() {
//     $('.js-hamburger').removeClass('__close');
//     $('.js-drawer-navi').slideUp();
//   });
  
// });

// Intersection Observer
new IntersectionObserverToggleClass({
  selector: '.observe_target', 
  className: 'observed', 
  toggle: true
});

// checking legacy IE
IsLegacyIE();

// checking OS
FindOS();

// checking browser
FindBrowser();