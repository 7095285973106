<template>
  <picture class="__main" :class="[data.mainSlide.current === 1 ? '__main--current' : data.mainSlide.current === 3 ? '__main--next' : '']">
    <source media="(min-width: 769px)" :srcset="`${props.assetsPath}/assets/images/site/home/home_image_main_01.jpg`">
    <source media="(max-width: 768px)" :srcset="`${props.assetsPath}/assets/images/site/home/home_image_main_01_mobile.jpg`">
    <img class="__image" :src="`${props.assetsPath}/assets/images/site/home/home_image_main_01.jpg`" alt="">
  </picture>
  <picture class="__main" :class="[data.mainSlide.current === 2 ? '__main--current' : data.mainSlide.current === 1 ? '__main--next' : '']">
    <source media="(min-width: 769px)" :srcset="`${props.assetsPath}/assets/images/site/home/home_image_main_02.jpg`">
    <source media="(max-width: 768px)" :srcset="`${props.assetsPath}/assets/images/site/home/home_image_main_02_mobile.jpg`">
    <img class="__image" :src="`${props.assetsPath}/assets/images/site/home/home_image_main_02.jpg`" alt="">
  </picture>
  <picture class="__main" :class="[data.mainSlide.current === 3 ? '__main--current' : data.mainSlide.current === 2 ? '__main--next' : '']">
    <source media="(min-width: 769px)" :srcset="`${props.assetsPath}/assets/images/site/home/home_image_main_03.jpg`">
    <source media="(max-width: 768px)" :srcset="`${props.assetsPath}/assets/images/site/home/home_image_main_03_mobile.jpg`">
    <img class="__image" :src="`${props.assetsPath}/assets/images/site/home/home_image_main_03.jpg`" alt="">
  </picture>
</template>

<script setup>
  import { reactive, onMounted } from 'vue';
  const props = defineProps({
    assetsPath: {
      type: String
    }
  });
  const data = reactive({
    mainSlide: {
      current: 1,
      slides: 3,
      duration: 4000
    }
  });
  const play = () => {
    if(data.mainSlide.current < data.mainSlide.slides) {
      data.mainSlide.current++;
    } else {
      data.mainSlide.current = 1;
    }
  }
  onMounted(() => {
    setInterval(() => {
      play();
    }, data.mainSlide.duration)
  });
</script>