<template>
  <div class="__grid-item"> 
    <img class="__image" :class="[data.movie.current === 0 ? '__image--current' : data.movie.current === 3 ? '__image--next' : '']" :src="`${props.assetsPath}/assets/images/site/home/${movies[0]}`" alt="">
    <img class="__image" :class="[data.movie.current === 1 ? '__image--current' : data.movie.current === 0 ? '__image--next' : '']" :src="`${props.assetsPath}/assets/images/site/home/${movies[1]}`" alt="">
    <img class="__image" :class="[data.movie.current === 2 ? '__image--current' : data.movie.current === 1 ? '__image--next' : '']" :src="`${props.assetsPath}/assets/images/site/home/${movies[2]}`" alt="">
    <img class="__image" :class="[data.movie.current === 3 ? '__image--current' : data.movie.current === 2 ? '__image--next' : '']" :src="`${props.assetsPath}/assets/images/site/home/${movies[3]}`" alt="">
  </div>
  <div class="__grid-item">
    <img class="__image" :class="[data.movie.current === 0 ? '__image--current' : data.movie.current === 3 ? '__image--next' : '']" :src="`${props.assetsPath}/assets/images/site/home/${movies[1]}`" alt="">
    <img class="__image" :class="[data.movie.current === 1 ? '__image--current' : data.movie.current === 0 ? '__image--next' : '']" :src="`${props.assetsPath}/assets/images/site/home/${movies[2]}`" alt="">
    <img class="__image" :class="[data.movie.current === 2 ? '__image--current' : data.movie.current === 1 ? '__image--next' : '']" :src="`${props.assetsPath}/assets/images/site/home/${movies[3]}`" alt="">
    <img class="__image" :class="[data.movie.current === 3 ? '__image--current' : data.movie.current === 2 ? '__image--next' : '']" :src="`${props.assetsPath}/assets/images/site/home/${movies[0]}`" alt="">
  </div>
  <div class="__grid-item">
    <img class="__image" :class="[data.movie.current === 0 ? '__image--current' : data.movie.current === 3 ? '__image--next' : '']" :src="`${props.assetsPath}/assets/images/site/home/${movies[2]}`" alt="">
    <img class="__image" :class="[data.movie.current === 1 ? '__image--current' : data.movie.current === 0 ? '__image--next' : '']" :src="`${props.assetsPath}/assets/images/site/home/${movies[3]}`" alt="">
    <img class="__image" :class="[data.movie.current === 2 ? '__image--current' : data.movie.current === 1 ? '__image--next' : '']" :src="`${props.assetsPath}/assets/images/site/home/${movies[0]}`" alt="">
    <img class="__image" :class="[data.movie.current === 3 ? '__image--current' : data.movie.current === 2 ? '__image--next' : '']" :src="`${props.assetsPath}/assets/images/site/home/${movies[1]}`" alt="">
  </div>
  <div class="__grid-item">
    <img class="__image" :class="[data.movie.current === 0 ? '__image--current' : data.movie.current === 3 ? '__image--next' : '']" :src="`${props.assetsPath}/assets/images/site/home/${movies[3]}`" alt="">
    <img class="__image" :class="[data.movie.current === 1 ? '__image--current' : data.movie.current === 0 ? '__image--next' : '']" :src="`${props.assetsPath}/assets/images/site/home/${movies[0]}`" alt="">
    <img class="__image" :class="[data.movie.current === 2 ? '__image--current' : data.movie.current === 1 ? '__image--next' : '']" :src="`${props.assetsPath}/assets/images/site/home/${movies[1]}`" alt="">
    <img class="__image" :class="[data.movie.current === 3 ? '__image--current' : data.movie.current === 2 ? '__image--next' : '']" :src="`${props.assetsPath}/assets/images/site/home/${movies[2]}`" alt="">
  </div>
</template>

<script setup>
  import { reactive, onMounted } from 'vue';
  const props = defineProps({
    assetsPath: {
      type: String
    }
  });
  const movies = [
    'home_image_slide_01.jpg',
    'home_image_slide_02.jpg',
    'home_image_slide_03.jpg',
    'home_image_slide_04.jpg',
  ]
  const data = reactive({
    movie: {
      current: 0,
      items: movies.length,
      duration: 4000
    }
  });
  const play = () => {
    if(data.movie.current < data.movie.items - 1) {
      data.movie.current++;
    } else {
      data.movie.current = 0;
    }
}
  onMounted(() => {
    window.addEventListener('load', () => {
      setInterval(() => {
        play();
      }, data.movie.duration)
    });
  });
</script>